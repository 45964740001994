import { createRouter, createWebHistory } from "vue-router";

import JwtService from "@/store/jwt.service";

import HomeView from "../views/HomeView.vue";
import PerfilMenuView from "../views/PerfilMenuView.vue";
import LoginView from "../views/LoginView.vue";
import CadastreView from "../views/CadastreView.vue";
import EsqueciSenha from "../views/EsqueciSenha.vue";
import QRCodeAssociar from "../views/QRCodeAssociar.vue";
import HistoricoPagamentos from "../views/HistoricoPagamentos.vue";
import LojaProdutos from "../views/LojaProdutos.vue";
import ProdutoView from "../views/ProdutoView.vue";
import ProdutoCheckoutView from "../views/ProdutoCheckoutView.vue";

const routes = [
  {
    path: "/", 
    name: "home", 
    component: HomeView, 
    meta: { requiresAuth: true }
  }, {
    path: "/perfil",
    name: "perfil",
    component: PerfilMenuView, 
    meta: { requiresAuth: true }
  }, {
    path: "/dadosPessoais",
    name: "dadosPessoais",
    component: () => import(/* webpackChunkName: "dadosPessoais" */ "../views/DadosPessoaisView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/alterarDadosPessoais", 
    name: "alterarDadosPessoais", 
    component: () => import(/* webpackChunkName: "alterarDadosPessoais" */ "../views/AlterarDadosPessoaisView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/alterarEmailCelular/:tipo", 
    name: "alterarEmailCelular", 
    component: () => import(/* webpackChunkName: "alterarEmailCelular" */ "../views/AlterarEmailCelularView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/dadosProfissionais",
    name: "dadosProfissionais",
    component: () => import(/* webpackChunkName: "dadosProfissionais" */ "../views/DadosProfissionaisView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/editarDadosProfissionais",
    name: "editarDadosProfissionais",
    component: () => import(/* webpackChunkName: "editarDadosProfissionais" */ "../views/EditarDadosProfissionaisView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/editarDadosProfissionais/:id",
    name: "editarDadosProfissionaisAlterar",
    component: () => import(/* webpackChunkName: "editarDadosProfissionais" */ "../views/EditarDadosProfissionaisView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/alterarDadosFormacao",
    name: "alterarDadosFormacao",
    component: () => import(/* webpackChunkName: "alterarDadosFormacao" */ "../views/AlterarDadosFormacaoView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/dadosEspecialidades",
    name: "dadosEspecialidades",
    component: () => import(/* webpackChunkName: "dadosEspecialidades" */ "../views/DadosEspecialidadesView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/editarDadosEspecialidade",
    name: "editarDadosEspecialidade",
    component: () => import(/* webpackChunkName: "editarDadosEspecialidade" */ "../views/EditarDadosEspecialidadeView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/editarDadosEspecialidade/:id",
    name: "editarDadosEspecialidadeAlterar",
    component: () => import(/* webpackChunkName: "editarDadosEspecialidade" */ "../views/EditarDadosEspecialidadeView.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/qrcodeAssociar", 
    name: "qrcodeAssociar", 
    component: QRCodeAssociar, 
    meta: { requiresAuth: true }
  }, {
    path: "/historico", 
    name: "historico", 
    component: HistoricoPagamentos, 
    meta: { requiresAuth: true }
  }, {
    path: "/loja", 
    name: "loja", 
    component: LojaProdutos, 
    meta: { requiresAuth: true }
  }, {
    path: "/adicionarCartao", 
    name: "adicionarCartao", 
    component: () => import(/* webpackChunkName: "adicionarCartao" */ "../views/AdicionarCartao.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/pendencia", 
    name: "pendencia", 
    component: () => import(/* webpackChunkName: "pendencia" */ "../views/PagamentoTitulo.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/pendenciaFormaPagamento", 
    name: "pendenciaFormaPagamento", 
    component: () => import(/* webpackChunkName: "pendenciaFormaPagamento" */ "../views/PagamentoForma.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/produtoReview", 
    name: "produtoReview", 
    component: () => import(/* webpackChunkName: "produtoReview" */ "../views/ProdutoReview.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/minhasAssociacoes", 
    name: "minhasAssociacoes", 
    component: () => import(/* webpackChunkName: "minhasAssociacoes" */ "../views/MinhasAssociacoes.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/gerenciarPagamentos", 
    name: "gerenciarPagamentos", 
    component: () => import(/* webpackChunkName: "gerenciarPagamentos" */ "../views/GerenciarPagamentos.vue"), 
    meta: { requiresAuth: true }
  }, {
    path: "/produto/:hash", 
    name: "produto", 
    component: ProdutoView
  }, {
    path: "/produtoCheckout/:hash", 
    name: "produtoCheckout", 
    component: ProdutoCheckoutView
  }, {
    path: "/login", 
    name: "login", 
    component: LoginView
  }, {
    path: "/register", 
    name: "register", 
    component: CadastreView
  }, {
    path: "/register/:idEmitenteML", 
    name: "registerEmitenteML", 
    component: CadastreView
  }, {
    path: "/associese", 
    name: "associese", 
    component: CadastreView
  }, {
    path: "/associese/:idEmitenteML", 
    name: "associeseEmitenteML", 
    component: CadastreView
  }, {
    path: "/register/:idEmitenteML/:idProjeto", 
    name: "registerEmitenteMLProjeto", 
    component: CadastreView
  }, {
    path: "/forgetPassword", 
    name: "forgetPassword", 
    component: EsqueciSenha
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (JwtService.getToken()) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
