<template>
  <div class="group-input">
    <label style="position: initial;">
      {{ label }} <span v-if="required" style="color: red">*</span>
    </label>
    <InputDropzoneInside 
      @file="insertFile" 
      @files="insertFiles" 
      @error="error" 
      style="margin: 0px; width: 100%; height: 100%; display: block; text-align: center; vertical-align: middle;"
    />
  </div>
</template>

<script>
import InputDropzoneInside from "./InputDropzoneInside.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InputDropzone", 
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "maxlength", 
    "required"
  ], 
  components: {
    InputDropzoneInside
  }, 
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  }, 
  methods: {
    async insertFiles(files) {
      for (let index = 0; index < files.length; index++) {
        await this.insertFile(files[index], true, false);
      }
    }, 
    async insertFile(file) {
      file.id = uuidv4();

      this.$emit("adicionar", {
        arquivos: this.files, 
        arquivo: file, 
        adicionar: true
      });
    },
    error(error) {
      this.$swal({
        icon: "error", 
        title: "Oops", 
        text: error, 
        timer: 4000, 
        timerProgressBar: true
      });
    }
  }
}
</script>

<style>

</style>