<template>
  <a class="dropzone-select tf-btn accent me-2 dz-clickable" v-bind="getRootProps()" style="text-transform: none; cursor: pointer;">
    <input v-bind="getInputProps()" />
    <span style="display: block; margin: 24px 0px;">
      Clique ou arraste e solte arquivos aqui
    </span>
  </a>
</template>

<script>
import { useDropzone } from "vue3-dropzone";

export default {
  name: "InputDropzoneInside", 
  setup(props, { emit }) {
    function onDrop(acceptFiles) {
      let filesFormated = [];

      let tamanhoArquivo = 0;
      
      if(props.video) {
        tamanhoArquivo = 1073741824;
      } else {
        tamanhoArquivo = 15728640;
      }

      acceptFiles.forEach(file => {
        if(file.size <= tamanhoArquivo) {
          let tipoPermitido = false;

          if((!props.video) && ((file.type == "application/pdf") || (file.type == "image/jpeg") || (file.type == "image/png") || (file.type == "image/jpg") || (file.type == "image/gif") || (file.type == "image/bmp") || (file.type == "image/tiff") || (file.type == "image/webp"))) {
            tipoPermitido = true;
          } else if((props.video) && ((file.type == "video/mp4") || (file.type == "video/webm"))) {
            tipoPermitido = true;
          }

          if(!tipoPermitido) {
            if(props.video) {
              emit("error", "O arquivo " + file.name + " não é do tipo MP4 ou WEBM.");
            } else {
              emit("error", "O arquivo " + file.name + " não é do tipo PDF, JPG, PNG, GIF, BMP, TIFF ou WEBP.");
            }
          } else {
            const reader = new FileReader();
            reader.onload = (e) => {
              let fileItem = {
                nome: file.name,
                arquivo: e.target.result,
                size: file.size,
                type: file.type
              };
              filesFormated.push(fileItem);

              if(filesFormated.length == acceptFiles.length) {
                emit("files", filesFormated);
              }
            };
            reader.readAsDataURL(file);
          }
        } else {
          if(props.video) {
            emit("error", "O arquivo " + file.name + " ultrapassa o limite de 1GB.");
          } else {
            emit("error", "O arquivo " + file.name + " ultrapassa o limite de 15MB.");
          }
        }
      });
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  }
}
</script>

<style scoped>
  .dropzone {
    border: 2px dashed #34bfa3;
    width: 100%;
    border-radius: 4px;
    padding: 20px;
    padding-top: 36px;
    text-align: center;
    min-height: 150px;
    background: white;
    display: block;
  }

  .dropzone .titulo {
    font-weight: 400;
    font-size: 1.1rem;
    color: #575962;
  }

  .dropzone .descricao {
    font-size: .85rem;
    color: #6f727d;
  }
</style>