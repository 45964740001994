import ApiService from "@/store/api.service";

const state = {
  pendenciaPagar: {}
};

const getters = {
  pendenciaPagar(state) {
    return state.pendenciaPagar;
  }
};

const mutations = {
  setPendenciaPagar(state, pendenciaPagar) {
    state.pendenciaPagar = pendenciaPagar;
  }
};

const actions = {
  updatePendenciaPagar(context, pendenciaPagar) {
    context.commit("setPendenciaPagar", pendenciaPagar);
  },
  listarFluxos(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/listarFluxos", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  obterFluxo(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/obterFluxo", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  obterFluxoDireto(context, dados) {
    return new Promise(resolve => {
      ApiService.post("paylogosAppAccess/obterFluxoDireto", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  pendenciaTotal360dias() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppFluxo/access/pendenciaTotal360dias").then(({ data }) => {
        resolve(data);
      });
    });
  },
  efetuarTransacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/efetuarTransacao", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  efetuarTransacaoDireta(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppAccess/efetuarTransacaoDireta", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  verificarTransacao(context, idZoopTransacao) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/verificarTransacao", {ID_ZOOP_TRANSACAO: idZoopTransacao}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  verificarTransacaoDireta(context, dados) {
    return new Promise(resolve => {
      ApiService.post("paylogosAppAccess/verificarTransacaoDireta", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  obterTransacaoPendenteBoleto(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/obterTransacaoPendenteBoleto", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  incluirFluxoProjeto(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/incluirFluxoProjeto", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  incluirFluxoProjetoDireto(context, dados) {
    return new Promise(resolve => {
      ApiService.post("paylogosAppAccess/incluirFluxoProjetoDireto", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  verificaSePagaAnuidade(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppFluxo/access/verificaSePagaAnuidade", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }
};

export default {
  state, 
  actions, 
  mutations, 
  getters
};