import ApiService from "@/store/api.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getEmitente() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/obterLogado").then(({ data }) => {
        resolve(data);
      });
    });
  },
  getEndereco(context, id) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/obterEndereco", {ID_EMITENTE_ENDERECO: id}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  getEmitenteFormacao() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/obterFormacao").then(({ data }) => {
        resolve(data);
      });
    });
  },
  getEmitenteEspecialidade(context, id) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/obterEmitenteEspecialidade", {ID_EMITENTE_ESPECIALIDADE: id}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  associarEmitente(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/associarEmitente", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  listarAssociacoesEmitente() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/listarAssociacoesEmitente").then(({ data }) => {
        resolve(data);
      });
    });
  },
  salvarEmitente(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/salvar", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  salvarEmitenteEndereco(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/salvarEndereco", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  salvarEmitenteFormacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/salvarFormacao", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  salvarEmitenteEspecialidade(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/salvarEmitenteEspecialidade", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  excluirEmitenteEndereco(context, id) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/excluirEndereco", {ID_EMITENTE_ENDERECO: id}).then(({ data }) => {
          resolve(data);
      });
    });
  },
  excluirEmitenteEspecialidade(context, id) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/excluirEspecialidade", {ID_EMITENTE_ESPECIALIDADE: id}).then(({ data }) => {
          resolve(data);
      });
    });
  },
  listarCartoes() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/listarCartoes").then(({ data }) => {
        resolve(data);
      });
    });
  },
  listarEnderecos() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/listarEnderecos").then(({ data }) => {
        resolve(data);
      });
    });
  },
  listarEspecialidades() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/listarEmitenteEspecialidade").then(({ data }) => {
        resolve(data);
      });
    });
  },
  listarEspecialidadesML() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/listarEmitenteEspecialidadeML").then(({ data }) => {
        resolve(data);
      });
    });
  },  
  salvarCartao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/salvarCartao", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  selecionarPrincipal(context, idZoopEmitenteCartao) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/selecionarPrincipal", {ID_ZOOP_EMITENTE_CARTAO: idZoopEmitenteCartao}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  temPIN() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppEmitente/access/temPIN").then(({ data }) => {
        resolve(data);
      });
    });
  },
  cadastrarPIN(context, pin) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/cadastrarPIN", {PIN: pin}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  validarPIN(context, pin) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/validarPIN", {PIN: pin}).then(({ data }) => {
        resolve(data);
      });
    });
  },
  enviarCodigoTransacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/enviarCodigoPinTransacao", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  verificarCodigoTransacao(context, codigo) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/verificarCodigoPinTransacao", {CODIGO: codigo}).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  getVerificarEmailCelularEmUso(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/verificarEmailCelularEmUso", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  enviarCodigoTransacaoTroca(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/enviarCodigoPinTransacaoAlterarEmailCelular", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  verificarCodigoTransacaoTroca(context, codigo) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/verificarCodigoPinTransacaoTroca", {CODIGO: codigo}).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  efetuarTrocaEmailCelular(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("paylogosAppEmitente/access/efetuarTrocaEmailCelular", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }
};

export default {
  state, 
  actions, 
  mutations, 
  getters
};