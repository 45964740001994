<template>
  <div class="group-input">
    <label>
      {{ label }} <span v-if="required" style="color: red">*</span>
    </label>
    <select :disabled="disabled" v-model="valor">
      <option value="0">{{ placeholder ? placeholder : 'Selecione uma opção' }}</option>
      <option v-for="item in items" :value="item.value" :key="item.value">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "InputSelect",
  props: [
    "modelValue", 
    "items", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  }
}
</script>

<style>

</style>